import { forEach, isArray, keys, pick, uniq } from 'lodash-es';
import { JSONInterface } from 'Interfaces/JsonInterface';
import uuid from 'uuid/v4';
import { isNullOrUndefined, pad } from '../../../utils/utils';
import { globalWindow } from '../../../global/global';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Locations } from '../../../Interfaces/LocationInterface';
import { DataPoint, GeoCodedData } from '../../../Interfaces/DataPoint';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';
import { parseScript } from './LookupFileFilter';
import FormUtils from './FormUtils';
import { getDiagram } from './DiagramUtils';
import { getFormUtils } from './FormUtilsHolder';
import { canView } from './RolesChecker';

export const generateName = () => {
  const date = new Date();
  const dateStr = `${pad(date.getDate())}${pad(date.getMonth() + 1)}${date.getFullYear()}`;
  const timeStr = `${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
  return `${dateStr}-${timeStr}`;
};

/*
  This function goes through the questions given and resets their values in the data point.
  Returns a new datapoint with default or null values for the questions encountered.
*/
export const resetValues = (questions): DataPoint => {
  let dataPoint: DataPoint = {};
  for (const qn of questions) {
    if (!qn.inVisible && !qn.deleted) {
      if (qn.default) {
        if (
          qn.type === 'IntQuestion' ||
                    qn.type === 'FloatQuestion' ||
                    (qn.type === 'CalculatedValueQuestion' && qn.numericOnly)
        ) {
          dataPoint[qn.id] = Number(qn.default);
        } else {
          dataPoint[qn.id] = qn.default;
        }
      } else {
        dataPoint[qn.id] = null;
      }
      if (canHaveSubQuestions(qn) && qn.triggerValues && qn.triggerValues.triggerValue) {
        for (const triggerValue of qn.triggerValues.triggerValue) {
          const dp = resetValues(triggerValue.action.subQuestions.question);
          dataPoint = Object.assign({}, dataPoint, dp);
        }
      }
    }
  }
  return dataPoint;
};
/*
  This function resets the values for subquestions under the given option.
*/
export const resetSubQuestionValues = (question: LooseObject, option: string): DataPoint => {
  const dataPoint: DataPoint = {};
  if (question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (triggerValue.value === option) {
        return resetValues(triggerValue.action.subQuestions.question);
      }
    }
  }
  return dataPoint;
};

export const initSubquestionValues = (
  question: LooseObject,
  option: string,
  formUtils,
  forms,
  parentQuestion,
): DataPoint => {
  const dataPoint: DataPoint = {};
  if (question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (triggerValue.value === option) {
        for (const q of triggerValue.action.subQuestions.question) {
          initQuestion(q, dataPoint, formUtils, forms, parentQuestion);
        }
      }
    }
  }
  return dataPoint;
};

export const skipHasChanged = (oldValue, newValue) => {
  let currentValue: boolean | null = null;
  if (oldValue === true || oldValue === 'true') {
    currentValue = true;
  } else if (oldValue === false || oldValue === 'false') {
    currentValue = false;
  }
  if (currentValue !== newValue) {
    return true;
  }
  return false;
};

export const initDataPoint = (
  dataPoint: DataPoint,
  model: FormInterface | LooseObject,
  clientPersist?: ClientPersistInterface,
  locationHierarchy?: Locations,
  parentQuestion?: LooseObject,
  forms?: LooseObject[],
): DataPoint => {
  const formUtils = getFormUtils(model);
  const newDataPoint = Object.assign({}, dataPoint);
  if (model.type !== 'TABLE') {
    newDataPoint['portalEditEntry'] = Date.now();
    newDataPoint['createdBy'] = globalWindow.userID;
  }
  newDataPoint.questionnaire_id = model.ref;

  delete newDataPoint['modified']; // remove the modified time, this would be reset in backend.
  const questions = formUtils.getQuestions();
  const ids = keys(questions);

  if (
    formUtils.showLocationHierarchy() &&
        clientPersist &&
        clientPersist.userLevel.trim().length > 0 &&
        clientPersist.userLocations.trim().length > 0
  ) {
    initLocationHierarchy(clientPersist, locationHierarchy, newDataPoint);
  }
  forEach(ids, (id) => {
    const qn: QuestionInterface = questions[id];
    if (!dataPoint[qn.id]) {
      initQuestion(qn, newDataPoint, formUtils, forms, parentQuestion);
    } else if (qn.type === questionTypes.CALCULATED_VALUE_QUESTION && qn.runOnCreate) {
      newDataPoint.freezedQuestions = {
        ...(newDataPoint.freezedQuestions || {}),
        [id]: true,
      };
    }
  });
  return newDataPoint;
};

export const initQuestion = (qn: LooseObject, newDataPoint: DataPoint, formUtils: FormUtils, forms, parentQuestion) => {
  if (qn.default && !newDataPoint[qn.id]) {
    if (
      qn.type === questionTypes.INT_QUESTION ||
            qn.type === questionTypes.FLOAT_QUESTION ||
            (qn.type === questionTypes.CALCULATED_VALUE_QUESTION && qn.numericOnly)
    ) {
      newDataPoint[qn.id] = Number(qn.default);
    } else if (qn.type === questionTypes.DATE_QUESTION) {
      const today = new Date();
      today.setDate(today.getDate() + Number(qn['default']));
      newDataPoint[qn.id] = `${today.getFullYear()}-${pad(today.getMonth() + 1)}-${pad(today.getDate())}`;
    } else if (qn.type === questionTypes.BOOLEAN_QUESTION) {
      newDataPoint[qn.id] = qn.default === 'Yes' ? true : qn.default === 'No' ? false : null;
    } else {
      newDataPoint[qn.id] = qn.default;
    }
  } else {
    if (qn.type === questionTypes.LOOKUP_VALUES_QUESTION) {
      initLookupValues(qn, formUtils, newDataPoint, parentQuestion);
    } else if (qn.type === questionTypes.TABLE_QUESTION) {
      newDataPoint[qn.id] = initTableQuestion(qn, forms);
    } else if (qn.type === questionTypes.STATUS_QUESTION) {
      newDataPoint[qn.id] = 'Submitted';
    }
  }
};

export const initLookupValues = (
  qn: LooseObject,
  formUtils: FormUtils,
  dataPoint: DataPoint,
  parentQuestion?: LooseObject,
) => {
  const options = qn.lookupValue && qn.lookupValue.split(';').length > 1;
  if (options) {
    const validOptions = parseScript(formUtils, qn, parentQuestion, dataPoint);
    const key = keys(validOptions);
    if (key.length === 1) {
      dataPoint[qn.id] = key[0];
    }
  }
};

export const initTableQuestion = (question, forms) => {
  const values: DataPoint[] = [];
  if (forms) {
    const tableForm = forms.find((f) => f.ref === question.table.columns.relation[0].ref);
    if (tableForm) {
      if (question.table.rows && question.table.rows.listItem) {
        for (const rowItem of question.table.rows.listItem) {
          const tempDataPoint: DataPoint = {
            Name: rowItem.id ? rowItem.id : rowItem.value,
            id: `DV-${uuid()}`,
          };
          const initializedTableDataPoint = initDataPoint(tempDataPoint, tableForm);
          values.push(initializedTableDataPoint);
        }
      } else {
        values[0] = initDataPoint({ id: `DV-${uuid()}` }, tableForm);
      }
    }
  }
  return values;
};
/*

*/
export const initLocationHierarchy = (clientPersist, locationHierarchy, dataPoint) => {
  if (locationHierarchy.length === 1) {
    return;
  }
  let level = Number(clientPersist.userLevel);
  const locations = clientPersist.userLocations.split(',');

  let parentChanged = false;
  let currentParent;
  if (locations.length === 1) {
    // ony assigned one location
    const location = locationHierarchy.find((l) => Number(l.key) === Number(locations[0]));
    currentParent = location.key;
    // level++;
  } else {
    level--;
    for (const loc of locations) {
      const location = locationHierarchy.find((l) => Number(l.key) === Number(loc));
      if (!currentParent) {
        currentParent = location.parent;
      }
      if (currentParent && currentParent !== location.parent) {
        parentChanged = true;
      }
    }
  }

  // Find the highest level with common parent if any.
  if (parentChanged) {
    console.log(parentChanged);
  } else {
    // set the parent values since assigned locations have the same parent.
    // level--;
    while (level >= 0) {
      dataPoint[`location${level + 1}`] = currentParent;
      const location = locationHierarchy.find((l) => Number(l.key) === Number(currentParent));
      currentParent = location.parent;
      level--;
    }
  }
};

export const getQuestionText = (text: string | undefined | null) => {
  if (text && text.trim().endsWith('---')) {
    return text.substring(0, text.trim().indexOf('---'));
  } else if (text) {
    return text;
  }
  return '';
};

/**
 * This function returns the option texts for a gievn set of option ids.
 */
export const getOptionTexts = (qn: QuestionInterface, values: string[] | string): string => {
  if (qn.type === questionTypes.SKIP_QUESTION || qn.type === questionTypes.BOOLEAN_QUESTION) {
    return getBoolOptionText(Array.isArray(values) ? values[0] : values);
  }
  const li = qn.listItems?.listItem;
  if (li) {
    const optionTexts = li
      .map((opt) => {
        if ((opt.id && values.indexOf(opt.id) !== -1) || values.indexOf(opt.value) !== -1) {
          return opt.value;
        }
        return null;
      })
      .filter((text) => !!text);
    return optionTexts.join(',');
  }
  return '';
};

export const getArrayOptionTexts = (qn: QuestionInterface, values: string[] | string): string[] => {
  if (qn.type === questionTypes.SKIP_QUESTION || qn.type === questionTypes.BOOLEAN_QUESTION) {
    return getArrayBoolOptionText(Array.isArray(values) ? values[0] : values);
  }
  const li = qn.listItems?.listItem;
  if (li) {
    const optionTexts = li
      .map((opt) => {
        if ((opt.id && values.indexOf(opt.id) !== -1) || values.indexOf(opt.value) !== -1) {
          return opt.value;
        }
        return null;
      })
      .filter((text) => !!text);
    return optionTexts;
  }
  return [];
};

/**
 * This function returns the option texts for a gievn set of option ids.
 */
export const getOptionCode = (qn: QuestionInterface, values: string[] | string): string => {
  if (isNullOrUndefined(values)) {
    return '';
  }
  if (qn.type === questionTypes.SKIP_QUESTION || qn.type === questionTypes.BOOLEAN_QUESTION) {
    return getBoolOptionText(values);
  }
  const li = qn.listItems?.listItem;
  if (li) {
    const optionTexts = li
      .map((opt) => {
        if ((opt.id && values.indexOf(opt.id) !== -1) || values.indexOf(opt.value) !== -1) {
          return opt.code;
        }
        return null;
      })
      .filter((text) => !!text);
    return optionTexts.join(',');
  }
  return '';
};

/**
 * When we have a calculated value question with no spss variable in the script and not set to run on create,
 * we need to check if we should run it. This happens only once unless the value is reset to the default value.
 */
export const calculateValue = (updated: boolean, question: LooseObject, dataPoint: DataPoint): boolean => {
  const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]* /g));
  let calc = false;
  if (!updated && allVariables.length === 0) {
    const dv = question['default']
      ? question.numericOnly
        ? Number(question['default'])
        : question['default']
      : null;
    calc =
            !question.runOnCreate &&
            (dataPoint[question.id] === dv || (isNullOrUndefined(dataPoint[question.id]) && dv === null))
              ? true
              : false;
  }
  if (question.id === 'approvedby') {
    const status = dataPoint['status'];
    if (status === 'approved' || (status === 'approvedwithcomments' && !dataPoint['approvedby'])) {
      return true;
    }
  }
  return calc;
};

export const getBoolOptionText = (value) => {
  return value === true || value === 'true' ? 'Yes' : value === false || value === 'false' ? 'No' : '';
};

export const getArrayBoolOptionText = (value) => {
  return value === true || value === 'true' ? ['Yes'] : value === false || value === 'false' ? ['No'] : [];
};

/**
 * Returns true if a question can have subquestions.
 */
export const canHaveSubQuestions = (question) => {
  switch (question.type) {
    case 'SelectOneQuestion':
    case 'SelectMultipleQuestion':
    case 'BooleanQuestion':
    case 'SkipQuestion':
      return true;
    default:
      return false;
  }
};

/**
 * This function checks if a question text has script or not.
 */
export const hasScript = (question) => {
  if (question.text) {
    const variables = uniq(question.text.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
    return variables.length > 0;
  }
  return false;
};

/**
 * Given a datapoint, this function returns an array of all the subform instances within it.
 */
export const getSubformValues = (dataPoint: DataPoint, formUtils: FormUtils) => {
  const questionIds = keys(dataPoint);
  let subForms = [];
  forEach(questionIds, (id) => {
    if (isArray(dataPoint[id]) && id !== 'files' && id !== 'drawings') {
      const question = formUtils.getQuestion(id);
      if (question.type === questionTypes.PART_OF_QUESTION || question.type === questionTypes.TASK_QUESTION) {
        subForms = subForms.concat(dataPoint[id]);
      }
    }
  });
  return subForms;
};

/**
 * This function retrieves the Subforms within a Mainform that do not have any diagram attached to them directly.
 * @param dataPoint - Main form Datapoint.
 * @param formUtils - FormUtils Object for the Main form.
 */
export const getSubformMainDiagramValues = (
  dataPoint: DataPoint,
  formUtils: FormUtils,
  mainFormDiagramName: string,
) => {
  const questionIds = keys(dataPoint);
  const subForms: DataPoint[] = [];
  forEach(questionIds, (id) => {
    if (isArray(dataPoint[id]) && id !== 'files' && id !== 'drawings') {
      const question = formUtils.getQuestion(id);
      if (question && (
        question.type === questionTypes.PART_OF_QUESTION || question.type === questionTypes.TASK_QUESTION
      )) {
        const value = dataPoint[id];
        for (const val of value) {
          const diagram = getDiagram(val['files']);
          if (!diagram || diagram === mainFormDiagramName) {
            subForms.push(val);
          }
        }
        // subForms = subForms.concat(dataPoint[id]);
      }
    }
  });
  return subForms;
};

export const isClientPaginate = (form) => {
  const formUtils = getFormUtils(form);
  const questions = formUtils.getQuestions();
  if (questions) {
    const estimateCount = Object.keys(questions).length * form.count;
    return !(estimateCount > 25000);
  }
  return true;
};

export const processGeocodedData = (geoCodedData: GeoCodedData, formUtils) => {
  if (geoCodedData.mappedLocations) {
    formUtils.setMappedLocations(geoCodedData.mappedLocations);
  }
  if (geoCodedData.data) {
    const locations = pick(geoCodedData.data, ['location1', 'location2', 'location3', 'location4']);
    if (keys(locations).length > 0) {
      let i = 1;
      while (i <= 4) {
        if (!geoCodedData.data[`location${i}`]) {
          geoCodedData.data[`location${i}`] = null;
        }
        i++;
      }
    }
    // updateAnswer(geoCodedData.data);
  }
  return geoCodedData;
};

export const isNumeric = (value) => {
  return !isNaN(Number(value)) && value !== '';
};

export const getQuestionPage = (model: FormInterface, questionId: string) => {
  if (model.questionPage) {
    for (const page of model.questionPage) {
      const found = findQuestion(page.question, questionId);
      if (found) {
        return page;
      }
    }
  }
  return null;
};

const findQuestion = (questions, questionId: string) => {
  for (const qn of questions) {
    if (qn.id === questionId) {
      return true;
    }
    if (qn.triggerValues && qn.triggerValues.triggerValue) {
      for (const triggerValue of qn.triggerValues.triggerValue) {
        if (triggerValue.action && triggerValue.action.subQuestions) {
          const found = findQuestion(triggerValue.action.subQuestions.question, questionId);
          if (found) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const updateSubFormTables = (dataPoint: DataPoint, newIds: JSONInterface, newRowIds: JSONInterface) => {
  const keys = Object.keys(dataPoint);
  keys.forEach((key) => {
    if (Array.isArray(dataPoint[key]) && key !== 'files') {
      dataPoint[key].forEach((sf) => {
        const id = sf.id;
        if (newIds[id]) {
          sf['id'] = newIds[id];
        }
        if (newRowIds[id]) {
          sf['row_id'] = newRowIds[id];
        }
        updateSubFormTables(sf, newIds, newRowIds);
      });
    }
  });
};

export const checkValidationRuleError = (
  id: string,
  ivr: Pick<DataPoint, 'invalidValidationRules'>,
  formUtils: FormUtils,
): { hasError: boolean; errorText?: string } => {
  let hasError = false;
  let errorText: string | undefined;
  const keys = Object.keys(ivr);
  keys.forEach((key) => {
    const invalid = ivr[key];
    if (invalid.indexOf(id) > -1) {
      const qn = formUtils.getQuestion(key);
      errorText = qn ? qn.text : undefined;
      hasError = true;
    }
  });
  return { hasError, errorText };
};

export const freezeFormData = (dataPoint: DataPoint, model: FormInterface) => {
  const formUtils = getFormUtils(model);
  const questions = formUtils.getQuestions();
  const ids = keys(questions);
  const  digitalSignatureQuestions: QuestionInterface[] = [];
  for (const id of ids) {
    const qn = questions[id];
    if (qn.type === questionTypes.DIGITAL_SIGNATURE_QUESTION && !qn.deleted && !qn.inVisible) {
      digitalSignatureQuestions.push(qn);
    }
  }
  const freezFormQuestionIds = digitalSignatureQuestions
    .map(
      (question) =>
        question.triggerValues?.triggerValue[0].action.subQuestions.question.find(
          (q: QuestionInterface) =>
            q.type === questionTypes.BOOLEAN_QUESTION,
        )?.id,
    )
    .filter((id) => id);
  return freezFormQuestionIds.some(
    (id: string) => dataPoint[id],
  );
};

export const getTextColor = (color: string) => {
  if (color.startsWith('#')) {
    const c = color.substring(1);      // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (luma > 128) {
      return 'black';
    }
  } else {
    if (['yellow', 'beige', 'white'].indexOf(color) > -1) {
      return 'black';
    }
  }
  return 'white';
};

const linkBuilder = (link: string) => `<a href='${link}' target='_blank'>${link}</a>`;

export const transformCalculatedValueLinks = (value: string) => {
  // @ts-ignore
  const links = value.match(/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm);
  let newValue = value;
  if (links) {
    links.forEach(link => newValue = newValue.replace(new RegExp(link, 'g'), linkBuilder(link)));
  }
  return newValue;
};

export const getSubQuestions = (
  question: QuestionInterface, option: string, clientPersist: ClientPersistInterface
) => {
  if (question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (triggerValue.value === option) {
        return triggerValue.action.subQuestions?.question?.filter(q => !q.deleted && canView(q, clientPersist));
      }
    }
  }
  return [];
};

export const haveValue = (questions: QuestionInterface[], dataPoint: DataPoint) => {
  for (const q of questions) {
    if (!isNullOrUndefined(dataPoint[q.id]) || (Array.isArray(dataPoint[q.id]) && dataPoint[q.id].length > 0)) {
      return true;
    }
  }
  return false;
};
