import { updateClientPersist } from 'actions/clientPersistActions';
import { getLocalization } from 'global/global';
import { useAppDispatch, useAppSelector } from 'index';
import * as React from 'react';
import { Form, NavDropdown } from 'react-bootstrap';

interface Language {
  value: string;
  name: string;
}
// https://learn.microsoft.com/en-us/azure/ai-services/translator/language-support
const FORM_LANGS: Language[] = [
  {value: 'af', name: 'Afrikaans'},
  {value: 'sq', name: 'Albanian'},
  {value: 'am', name: 'Amharic'},
  {value: 'ar', name: 'Arabic'},
  {value: 'hy', name: 'Armenian'},
  {value: 'az', name: 'Azerbaijani'},
  {value: 'eu', name: 'Basque'},
  {value: 'bg', name: 'Bulgarian'},
  {value: 'bn', name: 'Bengali'},
  {value: 'bs', name: 'Bosnian'},
  {value: 'my', name: 'Burmese (Myanmar)'},
  {value: 'ca', name: 'Catalan'},
  {value: 'lzh', name: 'Chinese (Literary)'},
  {value: 'zh-Hans', name: 'Chinese Simplified'},
  {value: 'zh-Hant', name: 'Chinese Traditional'},
  {value: 'hr', name: 'Croatian'},
  {value: 'cs', name: 'Czech'},
  {value: 'da', name: 'Danish'},
  {value: 'prs', name: 'Dari'},
  {value: 'nl', name: 'Dutch'},
  {value: 'en', name: 'English'},
  {value: 'et', name: 'Estonian'},
  {value: 'fj', name: 'Fijian'},
  {value: 'fi', name: 'Finnish'},
  {value: 'fil', name: 'Filipino (Philippines)'},
  {value: 'fr', name: 'French'},
  {value: 'fr-ca', name: 'French (Canada)'},
  {value: 'gl', name: 'Galician'},
  {value: 'ka', name: 'Georgian'},
  {value: 'de', name: 'German'},
  {value: 'el', name: 'Greek'},
  {value: 'gu', name: 'Gujarati (India)'},
  {value: 'ht', name: 'Haitian Creole'},
  {value: 'ha', name: 'Hausa'},
  {value: 'he', name: 'Hebrew'},
  {value: 'hi', name: 'Hindi'},
  {value: 'hu', name: 'Hungarian'},
  {value: 'is', name: 'Icelandic'},
  {value: 'ig', name: 'Igbo'},
  {value: 'id', name: 'Indonesian'},
  {value: 'ga', name: 'Irish'},
  {value: 'it', name: 'Italian'},
  {value: 'ja', name: 'Japanese'},
  {value: 'jv', name: 'Javanese'},
  {value: 'kn', name: 'Kannada'},
  {value: 'ks', name: 'Kashmiri'},
  {value: 'kk', name: 'Kazakh'},
  {value: 'km', name: 'Khmer'},
  {value: 'rw', name: 'Kinyarwanda'},
  {value: 'ko', name: 'Korean'},
  {value: 'ku', name: 'Kurdish (Central)'},
  {value: 'kmr', name: 'Kurdish (Northern)'},
  {value: 'lo', name: 'Lao'},
  {value: 'lv', name: 'Latvian'},
  {value: 'ln', name: 'Lingala'},
  {value: 'lt', name: 'Lithuanian'},
  {value: 'dsb', name: 'Lower Sorbian'},
  {value: 'lug', name: 'Luganda'},
  {value: 'mk', name: 'Macedonian'},
  {value: 'mai', name: 'Maithili'},
  {value: 'mg', name: 'Malagasy'},
  {value: 'ms', name: 'Malay'},
  {value: 'ml', name: 'Malayalam'},
  {value: 'mt', name: 'Maltese'},
  {value: 'mi', name: 'Maori'},
  {value: 'mr', name: 'Marathi'},
  {value: 'mn-Cyrl', name: 'Mongolian (Cyrillic)'},
  {value: 'mn-Mong', name: 'Mongolian (Traditional)'},
  {value: 'my', name: 'Myanmar'},
  {value: 'ne', name: 'Nepali'},
  {value: 'nb', name: 'Norwegian'},
  {value: 'nya', name: 'Nyanja'},
  {value: 'or', name: 'Odia'},
  {value: 'ps', name: 'Pashto'},
  {value: 'fa', name: 'Persian'},
  {value: 'pl', name: 'Polish'},
  {value: 'pt', name: 'Portuguese (Brazil)'},
  {value: 'pt-pt', name: 'Portuguese (Portugal)'},
  {value: 'pa', name: 'Punjabi'},
  {value: 'otq', name: 'Queretaro Otomi'},
  {value: 'ro', name: 'Romanian'},
  {value: 'run', name: 'Rundi'},
  {value: 'ru', name: 'Russian'},
  {value: 'sm', name: 'Samoan (Latin)'},
  {value: 'sr-Cyrl', name: 'Serbian (Cyrillic)'},
  {value: 'sr-Latn', name: 'Serbian (Latin)'},
  {value: 'st', name: 'Sesotho'},
  {value: 'nso', name: 'Sesotho sa Leboa'},
  {value: 'tn', name: 'Setswana'},
  {value: 'sd', name: 'Sindhi'},
  {value: 'si', name: 'Sinhala'},
  {value: 'sk', name: 'Slovak'},
  {value: 'sl', name: 'Slovenian'},
  {value: 'so', name: 'Somali (Arabic)'},
  {value: 'es', name: 'Spanish'},
  {value: 'sw', name: 'Swahili (Latin)'},
  {value: 'sv', name: 'Swedish'},
  {value: 'ty', name: 'Tahitian'},
  {value: 'ta', name: 'Tamil'},
  {value: 'tt', name: 'Tatar (Latin)'},
  {value: 'te', name: 'Telugu'},
  {value: 'th', name: 'Thai'},
  {value: 'bo', name: 'Tibetan'},
  {value: 'ti', name: 'Tigrinya'},
  {value: 'to', name: 'Tongan'},
  {value: 'tr', name: 'Turkish'},
  {value: 'tk', name: 'Turkmen (Latin)'},
  {value: 'uk', name: 'Ukrainian'},
  {value: 'hsb', name: 'Upper Sorbian'},
  {value: 'ur', name: 'Urdu'},
  {value: 'ug', name: 'Uyghur (Arabic)'},
  {value: 'uz', name: 'Uzbek'},
  {value: 'vi', name: 'Vietnamese'},
  {value: 'cy', name: 'Welsh'},
  {value: 'xh', name: 'Xhosa'},
  {value: 'yo', name: 'Yoruba'},
  {value: 'yua', name: 'Yucatec Maya'},
  {value: 'zu', name: 'Zulu'},
];

export default function FormLanguage() {
  const dispatch = useAppDispatch();
  const forms = useAppSelector(state => state.forms.collection);
  const clientPersist = useAppSelector(state => state.clientPersist);
  const [languages, setLanguages] = React.useState<Language[]>([]);

  React.useEffect(() => {
    const available = forms.map(f => f.lang).filter(l => l);
    const langs = FORM_LANGS.filter(fl => available.indexOf(fl.value) > -1);
    setLanguages(langs);
  }, [forms]);

  const onFormLangaugeChange = (e) => {
    dispatch(updateClientPersist({formLanguage: e.target.value}));
  };

  return (
    <NavDropdown.Item as={'li'}>
      <Form>
        <Form.Label>{getLocalization('selectFormLanguage')}</Form.Label>
        <Form.Control
          as="select"
          value={clientPersist.formLanguage}
          placeholder="select"
          size="sm"
          onChange={onFormLangaugeChange}
        >
          <option value="">{getLocalization('originalFormLanguage')}</option>
          {languages.map((lang) =>
            (<option key={`form-lang-${lang.value}`} value={lang.value}>{lang.name}</option>)
          )}
        </Form.Control>
      </Form>
    </NavDropdown.Item>
  );
}
